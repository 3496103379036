import React from 'react';
import './WearToRepair.css';
import medicalWearImage from '../../../images/MedicalWearImage.webp';
import orthoImplantInstrumentImage from '../../../images/OrthoImplantInstrument.webp';
import orthopedicConsumableImage from '../../../images/OrthopedicConsumable.webp';
import surgicalConsumableImage from '../../../images/SurgicalCOnsumable.webp';


const WearToRepair = () => {
  const cardContent = [
    {
      title: "Medical Wear",
      description: "Medical wear includes garments and accessories designed for healthcare settings.",
      buttonText: "- Learn More",
      buttonLink: "https://sabiote.in/orthopedic-implants-instruments/",
      backgroundImage: medicalWearImage
    },
    {
      title: "Orthopedic Implants & Instruments",
      description: "Orthopedic implants and instruments play a vital role in treating",
      buttonText: "- Learn More",
      buttonLink: "https://sabiote.in/orthopedic-implants-instruments/",
      backgroundImage: orthoImplantInstrumentImage
    },
    {
      title: "Surgical Consumables",
      description: "Surgical consumables are disposable items used in surgeries for hygiene and infection prevention",
      buttonText: "- Learn More",
      buttonLink: "https://sabiote.in/orthopedic-implants-instruments/",
      backgroundImage: surgicalConsumableImage
    },
    {
      title: "Orthopedic Consumables",
      description: "Orthopedic consumables are disposable items used in orthopedic procedures, such as sterile dressings",
      buttonText: "- Learn More",
      buttonLink: "https://sabiote.in/orthopedic-implants-instruments/",
      backgroundImage: orthopedicConsumableImage
    }
  ];

  const Card = ({ title, description, buttonText, buttonLink, backgroundImage }) => (
    <div className="card-box" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="overlay"></div>
      <div className='description-cover-wrap'>
        <h2>{title}</h2>
        <p>{description}</p>
        <a href={buttonLink}>{buttonText}</a>
      </div>
    </div>
  );

  return (
    <div className='ware-to-repair-box'>
      <h1>Your Partner in Health, From Wear to Repair</h1>
      <div className="cards-containing-box">
        {cardContent.map((content, index) => (
          <Card
            key={index}
            title={content.title}
            description={content.description}
            buttonText={content.buttonText}
            buttonLink={content.buttonLink}
            backgroundImage={content.backgroundImage}
          />
        ))}
      </div>
    </div>
  );
};

export default WearToRepair;
